// routes only tenants can see
import AboutMeView from '../../../view/tenant/account/AboutMeView';
import AccountOverviewView from '../../../view/tenant/account/AccountOverviewView';
import BookmarksView from '../../../view/tenant/BookmarksView';
import ChatView from '../../../view/tenant/ChatView';
import HouseholdView from '../../../view/tenant/account/HouseholdView';
import IncomeProofView from '../../../view/tenant/account/IncomeProofView';
import IntroductionVideoView from '../../../view/tenant/account/IntroductionVideoView';
import SchufaUploadView from '../../../view/tenant/account/SchufaUploadView';
import SearchProfilesView from '../../../view/tenant/SearchProfilesView';
import TenantProfileView from '../../../view/tenant/account/TenantProfileView';
import { IProtectedRouteProps } from '../../../AppRouter';
import { ROUTES } from '../routes';
import PrivateInsuranceView from 'view/tenant/account/PrivateInsuranceView';

// routes only tenants can see
export const TENANT_ROUTES: IProtectedRouteProps[] = [
  {
    path: ROUTES.tenantRoutes.account.aboutMe.path,
    Component: AboutMeView,
    props: { isApplicationFolderTunnel: false },
  },
  { path: ROUTES.tenantRoutes.account.household.path, Component: HouseholdView },
  { path: ROUTES.tenantRoutes.account.incomeProof.path, Component: IncomeProofView },
  { path: ROUTES.tenantRoutes.account.introductionVideo.path, Component: IntroductionVideoView },
  { path: ROUTES.tenantRoutes.account.overview.path, Component: AccountOverviewView },
  { path: ROUTES.tenantRoutes.account.profile.path, Component: TenantProfileView },
  { path: ROUTES.tenantRoutes.account.schufa.path, Component: SchufaUploadView },
  { path: ROUTES.tenantRoutes.account.privateInsurance.path, Component: PrivateInsuranceView },
  { path: ROUTES.tenantRoutes.bookmark.path, Component: BookmarksView },
  { path: ROUTES.tenantRoutes.chat.path, Component: ChatView },
  { path: ROUTES.tenantRoutes.searchProfiles.path, Component: SearchProfilesView },
  {
    path: ROUTES.tenantRoutes.account.applicationFolder.aboutMe,
    Component: AboutMeView,
    props: { isApplicationFolderTunnel: true },
  },
  {
    path: ROUTES.tenantRoutes.account.applicationFolder.profile,
    Component: TenantProfileView,
    props: { isApplicationFolderTunnel: true },
  },
  {
    path: ROUTES.tenantRoutes.account.applicationFolder.household,
    Component: HouseholdView,
    props: { isApplicationFolderTunnel: true },
  },
  {
    path: ROUTES.tenantRoutes.account.applicationFolder.introductionVideo,
    Component: IntroductionVideoView,
    props: { isApplicationFolderTunnel: true },
  },
  {
    path: ROUTES.tenantRoutes.account.applicationFolder.incomeProof,
    Component: IncomeProofView,
    props: { isApplicationFolderTunnel: true },
  },
  {
    path: ROUTES.tenantRoutes.account.applicationFolder.schufa,
    Component: SchufaUploadView,
    props: { isApplicationFolderTunnel: true },
  },
  {
    path: ROUTES.tenantRoutes.account.applicationFolder.privateInsurance,
    Component: PrivateInsuranceView,
    props: { isApplicationFolderTunnel: true },
  },
];
