import { useContext, useEffect, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import { FILE_UPLOAD_STATUS, IUpload } from '@wohnsinn/ws-ts-lib';
import { wohnsinnServices } from '../../App';
import UserContext from '../context/user.context';

const useTenantDocuments = (
  tenantProfileUid?: string
): {
  incomeProofDocumentList: IUpload[];
  schufaCheckDocumentList: IUpload[];
  privateInsuranceDocumentList: IUpload[];
  hasIncomeProofDocument: boolean;
  hasSchufaCheckDocument: boolean;
  hasPrivateInsuranceDocument: boolean;
} => {
  const { tenantService } = wohnsinnServices;
  const { user } = useContext(UserContext);
  const user_is_tenant = tenantProfileUid === user?.uid;
  const [schufaCheckDocumentList, setSchufaCheckList] = useState<IUpload[]>([]);
  const [privateInsuranceDocumentList, setPrivateInsuranceDocumentList] = useState<IUpload[]>([]);
  const [incomeProofDocumentList, setIncomeProofDocumentList] = useState<IUpload[]>([]);
  const [hasIncomeProofDocument, setHasIncomeProofDocument] = useState<boolean>(false);
  const [hasSchufaCheckDocument, setHasSchufaCheckDocument] = useState<boolean>(false);
  const [hasPrivateInsuranceDocument, setHasPrivateInsuranceDocument] = useState<boolean>(false);

  useEffect(() => {
    if (tenantProfileUid) {
      onSnapshot(tenantService.getSchufaCheckDocumentListRef(tenantProfileUid), async (colSnap) => {
        const hasSchufaDocuments = colSnap.docs.length > 0;
        setHasSchufaCheckDocument(hasSchufaDocuments);

        if (!hasSchufaDocuments && user_is_tenant) {
          await tenantService.setTenantHasSchufaDocuments(tenantProfileUid, false);
        }

        setSchufaCheckList(
          colSnap.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
              status: FILE_UPLOAD_STATUS.DONE,
              errors: [],
              uploadTask: null,
              storageRef: null,
            };
          })
        );
      });

      onSnapshot(tenantService.getPrivateInsuranceDocumentListRef(tenantProfileUid), async (colSnap) => {
        const hasPrivateInsuance = colSnap.docs.length > 0;
        setHasPrivateInsuranceDocument(hasPrivateInsuance);

        if (!hasPrivateInsuance && user_is_tenant) {
          await tenantService.setTenantHasPrivateInsuranceDocuments(tenantProfileUid, false);
        }

        setPrivateInsuranceDocumentList(
          colSnap.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
              status: FILE_UPLOAD_STATUS.DONE,
              errors: [],
              uploadTask: null,
              storageRef: null,
            };
          })
        );
      });

      onSnapshot(tenantService.getIncomeProofDocumentListRef(tenantProfileUid), async (colSnap) => {
        const hasIncomeProofDocument = colSnap.docs.length > 0;
        setHasIncomeProofDocument(hasIncomeProofDocument);

        if (!hasIncomeProofDocument && user_is_tenant) {
          await tenantService.setTenantHasIncomeProofDocuments(tenantProfileUid, false);
        }

        setIncomeProofDocumentList(
          colSnap.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
              status: FILE_UPLOAD_STATUS.DONE,
              errors: [],
              url: '',
              uploadTask: null,
              storageRef: null,
            };
          })
        );
      });
    }
  }, [tenantProfileUid]);
  return {
    incomeProofDocumentList,
    schufaCheckDocumentList,
    hasIncomeProofDocument,
    hasSchufaCheckDocument,
    hasPrivateInsuranceDocument,
    privateInsuranceDocumentList,
  };
};
export default useTenantDocuments;
