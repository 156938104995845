import { useTranslation } from 'react-i18next';
import ModalWrapper, { IModalWrapperButtonProps } from '../ModalWrapper';
import Text from 'component/atoms/typographie/Text';
import Spacer, { SPACER_SIZE } from 'component/atoms/Spacer';
import ModalContext from 'core/context/modal.context';
import { useContext, useState } from 'react';
import { ROUTES } from 'core/const/routes';
import CompletedFormCheck from 'component/atoms/CompletedFormCheck';
import UserContext from 'core/context/user.context';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const BeforeApplicationProfileOverviewModal = () => {
  const { closeModal, modalData } = useContext(ModalContext);
  const navigate = useNavigate();
  const { t } = useTranslation('common', {
    keyPrefix: 'component.molecules.modals.applicationFolder.BeforeApplicationProfileOverview',
  });
  const { t: f } = useTranslation('common', { keyPrefix: 'view.AccountOverviewView' });
  const { t: c } = useTranslation('common');
  const { t: r } = useTranslation('routes');
  const { tenantProfile } = useContext(UserContext);
  const [isApplying, setIsApplying] = useState(false);

  const apply = async () => {
    setIsApplying(true);
    await modalData.finalizeApplication();
    setIsApplying(false);
    closeModal();
  };

  const handleClick = (path: string) => {
    closeModal();
    return navigate(r(path));
  };

  const MODAL_BUTTONS: IModalWrapperButtonProps = {
    primary: {
      buttonText: t('registerAnyway'),
      onClick: apply,
      icon: isApplying ? faSpinner : null,
      spinIcon: isApplying,
      disabled: isApplying,
    },
    third: {
      buttonText: c('cancel'),
      onClick: closeModal,
    },
  };

  return (
    <ModalWrapper title={t('title')} buttons={MODAL_BUTTONS}>
      <Text align={'center'} tag={'p'}>
        {t('text')}
      </Text>

      <Spacer />

      <Grid container spacing={1}>
        {!tenantProfile?.photoUrl ? (
          <Grid item xs={12}>
            <CompletedFormCheck
              onClick={() => handleClick(ROUTES.tenantRoutes.account.applicationFolder.profile)}
              status={!!tenantProfile?.photoUrl}
              positiveText={c('uploaded')}
              negativeText={c('upload')}
              text={c('view.AccountView.TenantProfileView.profilePicture')}
            />
          </Grid>
        ) : null}
        {!tenantProfile?.aboutMe?.introductionVideo ? (
          <Grid item xs={12}>
            <CompletedFormCheck
              onClick={() => handleClick(ROUTES.tenantRoutes.account.applicationFolder.introductionVideo)}
              badge={f('recommended')}
              status={!!tenantProfile?.aboutMe?.introductionVideo}
              positiveText={c('uploaded')}
              negativeText={c('upload')}
              text={r(ROUTES.tenantRoutes.account.introductionVideo.title)}
            />
          </Grid>
        ) : null}
        {!tenantProfile?.hasSchufaDocs ? (
          <Grid item xs={12}>
            <CompletedFormCheck
              onClick={() => handleClick(ROUTES.tenantRoutes.account.applicationFolder.schufa)}
              status={tenantProfile?.hasSchufaDocs}
              positiveText={c('uploaded')}
              negativeText={c('upload')}
              text={r(ROUTES.tenantRoutes.account.schufa.title)}
            />
          </Grid>
        ) : null}
        {!tenantProfile?.hasIncomeProofDocs ? (
          <Grid item xs={12}>
            <CompletedFormCheck
              onClick={() => handleClick(ROUTES.tenantRoutes.account.applicationFolder.incomeProof)}
              status={tenantProfile?.hasIncomeProofDocs}
              positiveText={c('uploaded')}
              negativeText={c('upload')}
              text={r(ROUTES.tenantRoutes.account.incomeProof.title)}
            />
          </Grid>
        ) : null}
        {!tenantProfile?.hasPrivateInsuranceDocs ? (
          <Grid item xs={12}>
            <CompletedFormCheck
              onClick={() => handleClick(ROUTES.tenantRoutes.account.applicationFolder.privateInsurance)}
              status={tenantProfile?.hasPrivateInsuranceDocs}
              positiveText={c('uploaded')}
              negativeText={c('upload')}
              text={r(ROUTES.tenantRoutes.account.privateInsurance.title)}
            />
          </Grid>
        ) : null}
      </Grid>
      <Spacer size={SPACER_SIZE.MD} />
    </ModalWrapper>
  );
};

export default BeforeApplicationProfileOverviewModal;
