export const ROUTES = {
  adminRoutes: {
    overview: {
      path: 'adminRoutes.overview.path',
      title: 'adminRoutes.overview.title',
      label: 'adminRoutes.overview.label',
    },
    apartments: {
      path: 'adminRoutes.apartments.path',
      title: 'adminRoutes.apartments.title',
    },
    users: {
      path: 'adminRoutes.users.path',
      title: 'adminRoutes.users.title',
    },
  },
  landingPageRoutes: {
    about: {
      path: 'landingPageRoutes.about.path',
      title: 'landingPageRoutes.about.title',
      label: 'landingPageRoutes.about.label',
    },
    agb: {
      path: 'landingPageRoutes.agb.path',
      title: 'landingPageRoutes.agb.title',
    },
    contact: {
      path: 'landingPageRoutes.contact.path',
      title: 'landingPageRoutes.contact.title',
    },
    cooperative: {
      path: 'landingPageRoutes.cooperative.path',
      title: 'landingPageRoutes.cooperative.title',
      label: 'landingPageRoutes.cooperative.label',
    },
    hug: {
      path: 'landingPageRoutes.hug.path',
      title: 'landingPageRoutes.hug.title',
      label: 'landingPageRoutes.hug.label',
    },
    jobs: {
      path: 'landingPageRoutes.jobs.path',
      title: 'landingPageRoutes.jobs.title',
    },
    propertyManagement: {
      path: 'landingPageRoutes.propertyManagement.path',
      title: 'landingPageRoutes.propertyManagement.title',
    },
    realEstateAgent: {
      path: 'landingPageRoutes.realEstateAgent.path',
      title: 'landingPageRoutes.realEstateAgent.title',
    },
    imprint: {
      path: 'landingPageRoutes.imprint.path',
      title: 'landingPageRoutes.imprint.title',
    },
    landlord: {
      path: 'landingPageRoutes.landlord.path',
      title: 'landingPageRoutes.landlord.title',
      label: 'landingPageRoutes.landlord.label',
    },
    maintenance: {
      path: 'landingPageRoutes.maintenance.path',
      title: 'landingPageRoutes.maintenance.title',
    },
    press: {
      path: 'landingPageRoutes.press.path',
      title: 'landingPageRoutes.press.title',
    },
    prices: {
      path: 'landingPageRoutes.prices.path',
      title: 'landingPageRoutes.prices.title',
      label: 'landingPageRoutes.prices.label',
    },
    privacy: {
      path: 'landingPageRoutes.privacy.path',
      title: 'landingPageRoutes.privacy.title',
    },
    tenant: {
      path: 'landingPageRoutes.tenant.path',
      title: 'landingPageRoutes.tenant.title',
      label: 'landingPageRoutes.tenant.label',
    },
  },
  staticRoutes: {
    invitation: {
      title: 'staticRoutes.invitation.title',
      path: 'staticRoutes.invitation.path',
    },
    move: {
      overview: {
        title: 'staticRoutes.move.overview.title',
        path: 'staticRoutes.move.overview.path',
        label: 'staticRoutes.move.overview.label',
      },
      createSchufa: {
        path: 'staticRoutes.move.createSchufa.path',
        title: 'staticRoutes.move.createSchufa.title',
      },
      compareCheck24: {
        path: 'staticRoutes.move.compareCheck24.path',
        title: 'staticRoutes.move.compareCheck24.title',
      },
    },
    notFound: {
      path: 'staticRoutes.notFound.path',
      title: 'staticRoutes.notFound.title',
    },
    organization: {
      portfolio: {
        path: 'staticRoutes.organization.portfolio.path',
        title: 'staticRoutes.organization.portfolio.title',
        apartment: {
          path: 'staticRoutes.organization.portfolio.apartment.path',
          title: 'staticRoutes.organization.portfolio.apartment.title',
        },
      },
    },
    passwordResetConfirmation: {
      path: 'staticRoutes.passwordResetConfirmation.path',
      title: 'staticRoutes.passwordResetConfirmation.title',
    },
    redirect: {
      title: 'staticRoutes.redirect.title',
      path: 'staticRoutes.redirect.path',
    },
    registration: {
      createRegistration: {
        title: 'staticRoutes.registration.createRegistration.title',
        path: 'staticRoutes.registration.createRegistration.path',
        referrer: 'staticRoutes.registration.createRegistration.referrer',
      },
      activateAccount: {
        title: 'staticRoutes.registration.activateAccount.title',
        path: 'staticRoutes.registration.activateAccount.path',
      },
      tunnel: {
        title: 'staticRoutes.registration.tunnel.title',
        path: 'staticRoutes.registration.tunnel.path',
      },
    },
    services: {
      title: 'staticRoutes.services.title',
      path: 'staticRoutes.services.path',
      label: 'staticRoutes.services.label',
    },
    shareApartment: {
      title: 'staticRoutes.shareApartment.title',
      path: 'staticRoutes.shareApartment.path',
    },
    search: {
      title: 'staticRoutes.search.title',
      path: 'staticRoutes.search.path',
      label: 'staticRoutes.search.label',
    },
    searchTunnel: {
      title: 'staticRoutes.searchTunnel.title',
      path: 'staticRoutes.searchTunnel.path',
      label: 'staticRoutes.searchTunnel.label',
    },
    unsubscribe: {
      path: 'staticRoutes.unsubscribe.path',
      title: 'staticRoutes.unsubscribe.title',
    },
  },
  userRoutes: {
    registration: {
      createLandlordTunnel: {
        title: 'userRoutes.registration.createLandlordTunnel.title',
        path: 'userRoutes.registration.createLandlordTunnel.path',
      },
      createPassword: {
        title: 'userRoutes.registration.createPassword.title',
        path: 'userRoutes.registration.createPassword.path',
      },
      createTenantTunnel: {
        title: 'userRoutes.registration.createTenantTunnel.title',
        path: 'userRoutes.registration.createTenantTunnel.path',
      },
    },
    settings: {
      title: 'userRoutes.settings.title',
      path: 'userRoutes.settings.path',
      label: 'userRoutes.settings.label',
    },
  },
  landlordRoutes: {
    account: {
      path: 'landlordRoutes.account.path',
      title: 'landlordRoutes.account.title',
      label: 'landlordRoutes.account.label',
    },
    apartment: {
      create: {
        new: {
          path: 'landlordRoutes.apartment.create.new.path',
          title: 'landlordRoutes.apartment.create.new.title',
        },
        mainInformation: {
          path: 'landlordRoutes.apartment.create.mainInformation.path',
          title: 'landlordRoutes.apartment.create.mainInformation.title',
        },
        media: {
          path: 'landlordRoutes.apartment.create.media.path',
          title: 'landlordRoutes.apartment.create.media.title',
          label: 'landlordRoutes.apartment.create.media.label',
        },
        overview: {
          path: 'landlordRoutes.apartment.create.overview.path',
          title: 'landlordRoutes.apartment.create.overview.title',
          label: 'landlordRoutes.apartment.create.overview.label',
        },
        cost: {
          path: 'landlordRoutes.apartment.create.cost.path',
          title: 'landlordRoutes.apartment.create.cost.title',
          label: 'landlordRoutes.apartment.create.cost.label',
        },
        equipment: {
          path: 'landlordRoutes.apartment.create.equipment.path',
          title: 'landlordRoutes.apartment.create.equipment.title',
          label: 'landlordRoutes.apartment.create.equipment.label',
        },
        desiredTenant: {
          path: 'landlordRoutes.apartment.create.desiredTenant.path',
          title: 'landlordRoutes.apartment.create.desiredTenant.title',
          label: 'landlordRoutes.apartment.create.desiredTenant.label',
        },
        energySupply: {
          path: 'landlordRoutes.apartment.create.energySupply.path',
          title: 'landlordRoutes.apartment.create.energySupply.title',
          label: 'landlordRoutes.apartment.create.energySupply.label',
        },
        freeTexts: {
          path: 'landlordRoutes.apartment.create.freeTexts.path',
          title: 'landlordRoutes.apartment.create.freeTexts.title',
          label: 'landlordRoutes.apartment.create.freeTexts.label',
        },
        contactPerson: {
          path: 'landlordRoutes.apartment.create.contactPerson.path',
          title: 'landlordRoutes.apartment.create.contactPerson.title',
          label: 'landlordRoutes.apartment.create.contactPerson.label',
        },
      },
      list: {
        path: 'landlordRoutes.apartment.list.path',
        title: 'landlordRoutes.apartment.list.title',
        label: 'landlordRoutes.apartment.list.label',
      },
      mainInformation: {
        path: 'landlordRoutes.apartment.mainInformation.path',
        title: 'landlordRoutes.apartment.mainInformation.title',
        label: 'landlordRoutes.apartment.mainInformation.label',
      },
      address: {
        path: 'landlordRoutes.apartment.address.path',
        title: 'landlordRoutes.apartment.address.title',
        label: 'landlordRoutes.apartment.address.label',
      },
      cost: {
        path: 'landlordRoutes.apartment.cost.path',
        title: 'landlordRoutes.apartment.cost.title',
        label: 'landlordRoutes.apartment.cost.label',
      },
      equipment: {
        path: 'landlordRoutes.apartment.equipment.path',
        title: 'landlordRoutes.apartment.equipment.title',
        label: 'landlordRoutes.apartment.equipment.label',
      },
      desiredTenant: {
        path: 'landlordRoutes.apartment.desiredTenant.path',
        title: 'landlordRoutes.apartment.desiredTenant.title',
        label: 'landlordRoutes.apartment.desiredTenant.label',
      },
      energyPass: {
        path: 'landlordRoutes.apartment.energyPass.path',
        title: 'landlordRoutes.apartment.energyPass.title',
        label: 'landlordRoutes.apartment.energyPass.label',
      },
      freeTexts: {
        path: 'landlordRoutes.apartment.freeTexts.path',
        title: 'landlordRoutes.apartment.freeTexts.title',
        label: 'landlordRoutes.apartment.freeTexts.label',
      },
      media: {
        path: 'landlordRoutes.apartment.media.path',
        title: 'landlordRoutes.apartment.media.title',
        label: 'landlordRoutes.apartment.media.label',
      },
      contactPerson: {
        path: 'landlordRoutes.apartment.contactPerson.path',
        title: 'landlordRoutes.apartment.contactPerson.title',
        label: 'landlordRoutes.apartment.contactPerson.label',
      },
      new: {
        path: 'landlordRoutes.apartment.new.path',
        title: 'landlordRoutes.apartment.new.title',
      },
      overview: {
        path: 'landlordRoutes.apartment.overview.path',
        title: 'landlordRoutes.apartment.overview.title',
      },
      preview: {
        path: 'landlordRoutes.apartment.preview.path',
        title: 'landlordRoutes.apartment.preview.title',
      },
      applications: {
        path: 'landlordRoutes.apartment.applications.path',
        title: 'landlordRoutes.apartment.applications.title',
      },
    },
  },
  tenantRoutes: {
    account: {
      aboutMe: {
        path: 'tenantRoutes.account.aboutMe.path',
        title: 'tenantRoutes.account.aboutMe.title',
      },
      household: {
        path: 'tenantRoutes.account.household.path',
        title: 'tenantRoutes.account.household.title',
      },
      incomeProof: {
        path: 'tenantRoutes.account.incomeProof.path',
        title: 'tenantRoutes.account.incomeProof.title',
      },
      introductionVideo: {
        path: 'tenantRoutes.account.introductionVideo.path',
        title: 'tenantRoutes.account.introductionVideo.title',
      },
      overview: {
        path: 'tenantRoutes.account.overview.path',
        title: 'tenantRoutes.account.overview.title',
        label: 'tenantRoutes.account.overview.label',
      },
      profile: {
        path: 'tenantRoutes.account.profile.path',
        title: 'tenantRoutes.account.profile.title',
      },
      schufa: {
        path: 'tenantRoutes.account.schufa.path',
        title: 'tenantRoutes.account.schufa.title',
      },
      privateInsurance: {
        path: 'tenantRoutes.account.privateInsurance.path',
        title: 'tenantRoutes.account.privateInsurance.title',
      },
      applicationFolder: {
        aboutMe: 'tenantRoutes.account.applicationFolder.aboutMe',
        profile: 'tenantRoutes.account.applicationFolder.profile',
        household: 'tenantRoutes.account.applicationFolder.household',
        incomeProof: 'tenantRoutes.account.applicationFolder.incomeProof',
        introductionVideo: 'tenantRoutes.account.applicationFolder.introductionVideo',
        schufa: 'tenantRoutes.account.applicationFolder.schufa',
        privateInsurance: 'tenantRoutes.account.applicationFolder.privateInsurance',
      },
    },
    bookmark: {
      title: 'tenantRoutes.bookmark.title',
      path: 'tenantRoutes.bookmark.path',
      label: 'tenantRoutes.bookmark.label',
    },
    chat: {
      path: 'tenantRoutes.chat.path',
      title: 'tenantRoutes.chat.title',
      label: 'tenantRoutes.chat.label',
    },
    searchProfiles: {
      path: 'tenantRoutes.searchProfiles.path',
      title: 'tenantRoutes.searchProfiles.title',
    },
  },
  socialMedia: {
    instagram: {
      path: 'socialMedia.instagram.path',
      img: 'socialMedia.instagram.img',
    },
    facebook: {
      path: 'socialMedia.facebook.path',
      img: 'socialMedia.facebook.img',
    },
    linkedin: {
      path: 'socialMedia.linkedin.path',
      img: 'socialMedia.linkedin.img',
    },
    twitter: {
      path: 'socialMedia.twitter.path',
      img: 'socialMedia.twitter.img',
    },
  },
  version: {
    path: 'version.path',
    title: 'version.title',
  },
};
