import { TableCell, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Text from 'component/atoms/typographie/Text';
import { FC } from 'react';
import styles from 'component/atoms/TableCellLink/TableCellLink.module.scss';

const TableCellLink: FC<{
  noWrap?: boolean;
  toLink: string;
  info?: string | number;
  showToolTip?: boolean;
  children?: any;
  showInfo?: boolean;
}> = ({ toLink, info, showToolTip = false, children, showInfo = true, noWrap = true }) => {
  const navigate = useNavigate();

  if (showToolTip) {
    return (
      <TableCell className={styles.hover} onClick={() => navigate(toLink)}>
        <Tooltip title={info ?? null}>
          <Text noWrap={noWrap}> {info ?? '-'}</Text>
        </Tooltip>
      </TableCell>
    );
  }
  return (
    <TableCell className={styles.hover} onClick={() => navigate(toLink)}>
      {showInfo && <Text noWrap={noWrap}> {info ?? '-'}</Text>}
      {children}
    </TableCell>
  );
};

export default TableCellLink;
