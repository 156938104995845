import { FC } from 'react';
import { IHousehold } from '@wohnsinn/ws-ts-lib';
import PageLayout from '../../../../component/layouts/PageLayout';
import { ROUTES } from '../../../../core/const/routes';
import AccountBreadcrumbs from '../../../../component/molecules/Breadcrumbs/AccountBreadcrumbs';
import TenantHouseHoldForm from '../../../../component/forms/tenant/TenantHouseHoldForm';
import { useTranslation } from 'react-i18next';
import { IAccountStepperViewProps } from '../AboutMeView';
import TunnelStepper, {
  ITunnelStepperStepProps,
  TUNNEL_STEP_STATE,
} from '../../../../component/organisms/Tunnels/TunnelStepper';

export const DEFAULT_HOUSEHOLD_DATA: IHousehold = {
  numberOfPeopleMovingIn: null,
  arePetsAllowed: null,
  canBeSubsidized: null,
  isSharedUsagePossible: null,
  pets: null,
  monthlyIncome: null,
  numberOfChildrenMovingIn: null,
  hasPledge: null,
};

const HouseHoldView: FC<IAccountStepperViewProps> = ({ isApplicationFolderTunnel = false }) => {
  const { t: r } = useTranslation('routes');

  const APPLICATION_FOLDER_STEPS_HOUSEHOLD: ITunnelStepperStepProps[] = [
    { label: 'Profil', state: TUNNEL_STEP_STATE.ACTIVE, link: ROUTES.tenantRoutes.account.applicationFolder.profile },
    {
      label: 'Haushalt',
      state: TUNNEL_STEP_STATE.CURRENT,
      link: ROUTES.tenantRoutes.account.applicationFolder.household,
    },
    {
      label: 'Vorstellungsvideo',
      state: TUNNEL_STEP_STATE.INACTIVE,
      link: ROUTES.tenantRoutes.account.applicationFolder.introductionVideo,
    },
    {
      label: 'Über mich',
      state: TUNNEL_STEP_STATE.INACTIVE,
      link: ROUTES.tenantRoutes.account.applicationFolder.aboutMe,
    },
    { label: 'Schufa', state: TUNNEL_STEP_STATE.INACTIVE, link: ROUTES.tenantRoutes.account.applicationFolder.schufa },
    {
      label: 'Einkommensnachweis',
      state: TUNNEL_STEP_STATE.INACTIVE,
      link: ROUTES.tenantRoutes.account.applicationFolder.incomeProof,
    },
    {
      label: 'Haftpflicht',
      state: TUNNEL_STEP_STATE.INACTIVE,
      link: ROUTES.tenantRoutes.account.applicationFolder.privateInsurance,
    },
  ];

  return (
    <PageLayout showPageTitle={false} pageTitle={r(ROUTES.tenantRoutes.account.household.title)} showBackButton>
      {isApplicationFolderTunnel ? (
        <TunnelStepper steps={APPLICATION_FOLDER_STEPS_HOUSEHOLD} />
      ) : (
        <AccountBreadcrumbs page={ROUTES.tenantRoutes.account.household} />
      )}
      <TenantHouseHoldForm isApplicationFolderTunnel={isApplicationFolderTunnel} />
    </PageLayout>
  );
};

export default HouseHoldView;
